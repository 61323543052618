import { LinkedInAnalytics } from "./utils/linkedin-analytics";
import LogRocket from "logrocket";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { carrierAssureDashboardLayout } from "./assets/images";
import { generateMetaTags } from "./utils/meta-tags";
import { getGrowthbookInstance } from "./utils/growthbook.server";
import { getNotification } from "./utils/notification.server";
import { json } from "@remix-run/node";
import { viewGAPage } from "./utils/google-analytics.client";
import { Anchor, loadingBar, notification as toast } from "./components/lib";
import { Fragment, useEffect } from "react";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useNavigation,
  useRouteError,
  useSubmit,
} from "@remix-run/react";
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import {
  getBackendVersion,
  getBitBucketCommit,
  sessionStorage,
  setBackendVersion,
  setBitBucketCommit,
} from "./utils/session.server";
import "./tailwind.css";

const loader = async ({ request }: LoaderFunctionArgs) => {
  const session = await sessionStorage.getSession(
    request.headers.get("Cookie"),
  );
  const notification = getNotification(session);

  const gb = await getGrowthbookInstance();
  const currentBackendVersion = gb.getFeatureValue(
    "back_end_version",
    true,
  ) as unknown as string;

  const bitbucketCommit = getBitBucketCommit(session);

  const storedBackendVersion = getBackendVersion(session);

  if (!bitbucketCommit || !storedBackendVersion) {
    setBitBucketCommit(session, process.env.BITBUCKET_COMMIT);
    setBackendVersion(session, currentBackendVersion);

    return json(
      {
        ENV: {
          BITBUCKET_COMMIT: process.env.BITBUCKET_COMMIT,
          GA_TRACKING_ID: process.env.GA_TRACKING_ID,
          LINKEDIN_PARTNER_ID: process.env.LINKEDIN_PARTNER_ID ?? null,
          LOGROCKET_APP_ID: process.env.LOGROCKET_APP_ID,
          MAPS_API_KEY: process.env.MAPS_API_KEY,
          NODE_ENV: process.env.NODE_ENV,
          S3_URI: process.env.S3_URI,
          SENTRY_DSN: process.env.SENTRY_DSN,
          STRIPE_PRICING_TABLE_ID: process.env.STRIPE_PRICING_TABLE_ID,
          STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY,
        },
        currentBackendVersion,
        isNewVersion: false,
        notification,
      },
      {
        headers: {
          "Cache-Control":
            "no-store, no-cache, must-revalidate, proxy-revalidate",
          "Set-Cookie": await sessionStorage.commitSession(session),
        },
      },
    );
  }

  setBitBucketCommit(session, process.env.BITBUCKET_COMMIT);
  setBackendVersion(session, currentBackendVersion);

  return json(
    {
      ENV: {
        BITBUCKET_COMMIT: process.env.BITBUCKET_COMMIT,
        GA_TRACKING_ID: process.env.GA_TRACKING_ID,
        LINKEDIN_PARTNER_ID: process.env.LINKEDIN_PARTNER_ID ?? null,
        LOGROCKET_APP_ID: process.env.LOGROCKET_APP_ID,
        MAPS_API_KEY: process.env.MAPS_API_KEY,
        NODE_ENV: process.env.NODE_ENV,
        S3_URI: process.env.S3_URI,
        SENTRY_DSN: process.env.SENTRY_DSN,
        STRIPE_PRICING_TABLE_ID: process.env.STRIPE_PRICING_TABLE_ID,
        STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY,
      },
      currentBackendVersion,
      isNewVersion:
        bitbucketCommit !== process.env.BITBUCKET_COMMIT ||
        currentBackendVersion !== storedBackendVersion,
      notification,
    },
    {
      headers: {
        "Cache-Control":
          "no-store, no-cache, must-revalidate, proxy-revalidate",
        "Set-Cookie": await sessionStorage.commitSession(session),
      },
    },
  );
};

const links: LinksFunction = () => [
  // Favicons
  {
    href: "/apple-touch-icon.png?v=2",
    rel: "apple-touch-icon",
    sizes: "180x180",
  },
  {
    href: "/favicon-32x32.png?v=2",
    rel: "icon",
    sizes: "32x32",
    type: "image/png",
  },
  {
    href: "/favicon-16x16.png?v=2",
    rel: "icon",
    sizes: "16x16",
    type: "image/png",
  },
  { href: "/site.webmanifest?v=2", rel: "manifest" },
  {
    color: "#5bbad5",
    href: "/safari-pinned-tab.svg?v=2",
    rel: "mask-icon",
  },
  {
    color: "#5bbad5",
    href: "/favicon.ico?v=2",
    rel: "shortcut icon",
  },
  /* Museo Sans Rounded 400, 700 */
  {
    as: "font",
    crossOrigin: "anonymous",
    href: "https://use.typekit.net/af/7d47d7/000000000000000077359965/30/l?primer=81a69539b194230396845be9681d114557adfb35f4cccc679c164afb4aa47365&fvd=n5&v=3",
    rel: "preload",
    type: "font/woff2",
  },
  {
    as: "font",
    crossOrigin: "anonymous",
    href: "https://use.typekit.net/af/d39206/00000000000000007735994a/30/l?primer=81a69539b194230396845be9681d114557adfb35f4cccc679c164afb4aa47365&fvd=n7&v=3",
    rel: "preload",
    type: "font/woff2",
  },
];

const meta: MetaFunction<typeof loader> = ({ data }) =>
  generateMetaTags({
    additionalTags:
      data &&
      "sentryTrace" in data &&
      typeof data.sentryTrace === "string" &&
      "sentryBaggage" in data &&
      typeof data.sentryBaggage === "string"
        ? [
            {
              content: data.sentryTrace,
              name: "sentry-trace",
            },
            {
              content: data.sentryBaggage,
              name: "baggage",
            },
          ]
        : undefined,
    description:
      "Carrier Assure is the first software that analyzes hundreds of thousands of data points predicting how a carrier will perform for you. Sign up today!",
    title: "Carrier Assure",
  });

const App = () => {
  const { ENV, notification, isNewVersion } = useLoaderData<typeof loader>();

  const location = useLocation();

  const submit = useSubmit();

  // Show notification on the session.
  useEffect(() => {
    if (notification?.type) toast[notification.type](notification.message);
  }, [notification?.id, notification?.message, notification?.type]);

  // Show loading bar on every page navigation.
  const navigation = useNavigation();
  useEffect(() => {
    if (navigation.state === "idle") loadingBar.done();
    else loadingBar.start();
  }, [navigation.state]);

  // Avoid strict mode double render
  useEffect(() => {
    const toastTimeout = setTimeout(() => {
      if (isNewVersion) {
        toast.info("There is a new version of the App. Please log in again.");

        setTimeout(() => {
          submit({}, { action: "/logout", method: "POST" });
        }, 5000);
      }
    }, 0);

    return () => {
      clearTimeout(toastTimeout);
    };
  }, [isNewVersion, submit]);

  useEffect(() => {
    if (ENV.GA_TRACKING_ID !== null) {
      viewGAPage(location.pathname, ENV.GA_TRACKING_ID);
    }
  }, [location, ENV.GA_TRACKING_ID]);

  useEffect(() => {
    if (
      ENV.BITBUCKET_COMMIT &&
      ENV.LOGROCKET_APP_ID &&
      ENV.NODE_ENV === "production"
    ) {
      LogRocket.init(ENV.LOGROCKET_APP_ID, {
        release: ENV.BITBUCKET_COMMIT,
      });
    }
  }, [ENV.BITBUCKET_COMMIT, ENV.LOGROCKET_APP_ID, ENV.NODE_ENV]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="flex flex-col overscroll-none bg-gray-900 text-white antialiased">
        {process.env.NODE_ENV === "development" ||
        !ENV.GA_TRACKING_ID ? null : (
          <Fragment>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${ENV.GA_TRACKING_ID}`}
            />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${ENV.GA_TRACKING_ID}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </Fragment>
        )}
        <Outlet />
        {toast.rootEl}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`,
          }}
        />
        {/* We need to pass the Linkedin key here, since we don't have access to the window object */}
        <LinkedInAnalytics linkedInPartnerId={ENV.LINKEDIN_PARTNER_ID} />
        <ScrollRestoration
          getKey={(location) => {
            if (location.pathname === "/") {
              return location.pathname;
            }

            return location.key;
          }}
        />
        <Scripts />
      </body>
    </html>
  );
};

const ErrorBoundary = () => {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  const isRouteError = isRouteErrorResponse(error);

  return (
    <html lang="en" style={{ height: "100%" }}>
      <head>
        <Meta />
        <Links />
      </head>
      <body className="relative h-full antialiased">
        <main
          style={{
            backgroundImage: `url(${carrierAssureDashboardLayout})`,
          }}
          className="grid min-h-full place-items-center bg-gray-900 bg-cover bg-no-repeat px-6 py-24 sm:py-32 lg:px-8"
        >
          <div className="text-center">
            <p className="text-base font-bold text-sky-400">
              {isRouteError ? "404" : "500"}
            </p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-50 sm:text-5xl">
              {isRouteError ? "Page not found" : "Unexpected error"}
            </h1>
            <p className="mt-6 text-base leading-7 text-gray-300">
              Sorry,{" "}
              {isRouteError
                ? "we couldn't find the page you're looking for."
                : "something went wrong on our servers."}
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Anchor to="/" reloadDocument>
                Go back home
              </Anchor>
              <a
                href="mailto:tech@carrierassure.com"
                className="text-sm font-bold text-gray-50"
              >
                Contact support <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </main>
        <Scripts />
        <div className="sr-only absolute bottom-0 w-96 overflow-hidden truncate text-xs text-transparent">
          <p>{(error as Error)?.message}</p>
          <p>{(error as Error)?.stack}</p>
        </div>
      </body>
    </html>
  );
};

export { ErrorBoundary, loader, meta, links };

export default App;
