import { Card } from "~/components/card";
import type { ResponseAttr } from "~/services/utils";
import type { UserAttr } from "~/services/users.server";
import { ValidatedForm } from "remix-validated-form";
import { apiClient } from "~/utils/api-client/api-client.server";
import { carrierAssureLogo } from "~/assets/images";
import { getGrowthbookInstance } from "~/utils/growthbook.server";
import { useId } from "react";
import { withZod } from "@remix-validated-form/with-zod";
import { z } from "zod";
import type { ActionFunctionArgs, LoaderFunctionArgs } from "@remix-run/node";
import { Button, Input, ValidatedInput } from "~/components/lib";
import { Form, Link, useLoaderData, useNavigation } from "@remix-run/react";
import { Mail, Unlock } from "lucide-react";
import { authenticate, getAuth } from "~/utils/authentication.server";
import { json, redirect } from "@remix-run/node";

const loader = async ({ request }: LoaderFunctionArgs) => {
  const auth = await getAuth(request);
  const gb = await getGrowthbookInstance();
  const isCustomErrorOn = gb.isOn("custom_errors");

  if (auth) {
    const { data: user } = await apiClient.query<ResponseAttr<UserAttr>>({
      accessToken: auth.accessToken,
      endpoint: `/users/detail/${auth.userId}`,
    });

    throw redirect(user.roleType === "superAdmin" ? "/admin/carriers" : "/");
  }

  return json({
    isCustomErrorOn,
  });
};

const validator = withZod(
  z.object({
    email: z
      .string()
      .min(1, { message: "Email is required." })
      .email("Invalid email format."),
    password: z.string().min(1, { message: "Password is required." }),
  }),
);

const SignIn = () => {
  const formId = useId();
  const { isCustomErrorOn } = useLoaderData<typeof loader>();

  const navigation = useNavigation();
  const isSigningIn = navigation.state === "submitting";

  return (
    <div className="mt-28 flex flex-col items-center sm:mt-0 sm:min-h-screen sm:justify-center">
      <Card className="w-full max-w-lg px-6 pb-7 sm:px-11 lg:px-16">
        <img
          src={carrierAssureLogo}
          alt="Carrier Assure logo with name of the company"
          className="mx-auto mt-11 h-14 w-40 object-contain sm:mt-7"
          width={170.08}
          height={56.77}
        />
        {isCustomErrorOn ? (
          <ValidatedForm
            reloadDocument
            id={formId}
            validator={validator}
            noValidate
            method="POST"
            className="space-y-5 pt-9 sm:pt-7"
          >
            <ValidatedInput
              name="email"
              label="Email"
              placeholder="Email address"
              leadingIcon={Mail}
              autoComplete="email"
              type="email"
              required
            />
            <ValidatedInput
              name="password"
              label="password"
              placeholder="Password"
              leadingIcon={Unlock}
              type="password"
              autoComplete="current-password"
              required
            />
          </ValidatedForm>
        ) : (
          <Form id={formId} method="POST" className="space-y-5 pt-9 sm:pt-7">
            <Input
              name="email"
              label="Email"
              placeholder="Email address"
              leadingIcon={Mail}
              autoComplete="email"
              type="email"
              required
            />
            <Input
              name="password"
              label="password"
              placeholder="Password"
              leadingIcon={Unlock}
              type="password"
              autoComplete="current-password"
              required
            />
          </Form>
        )}
        <div className="pt-8">
          <Button
            form={formId}
            status={isSigningIn ? "loading" : "idle"}
            size="lg"
            block
          >
            Sign In
          </Button>
        </div>
        <div className="mt-6 text-center">
          <Link
            to="/forgot-password"
            prefetch="intent"
            className="text-sm italic text-gray-300 hover:underline sm:text-base"
          >
            Forgot Password?
          </Link>
        </div>
      </Card>
      <p className="mt-12 text-center text-sm text-white sm:text-base">
        Don&apos;t have an account? <br aria-hidden className="sm:hidden" />
        <Link
          prefetch="intent"
          to="/sign-up"
          className="font-bold text-sky-400 hover:underline"
        >
          Sign up.
        </Link>
      </p>
    </div>
  );
};

const action = ({ request }: ActionFunctionArgs) =>
  authenticate(request, { with2FACode: false });

export { loader, action };
export default SignIn;
